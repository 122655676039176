import * as configuration from 'utils/configuration/catalogs';
import { get } from 'utils/http';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const catalogsIndexUrl = baseUrl + configuration.CATALOGS_INDEX;
const organizationTypesUrl =
  baseUrl + configuration.CATALOGS_ORGANIZATION_TYPES;
const archetypesUrl = baseUrl + configuration.CATALOGS_ARCHETYPES;
const dataLinkTypesUrl = baseUrl + configuration.CATALOGS_DATALINK_TYPES;
const subscriptionTypesUrl =
  baseUrl + configuration.CATALOGS_SUBSCRIPTION_TYPES;
const organizationCategoriesUrl =
  baseUrl + configuration.CATALOGS_ORGANIZATION_CATEGORIES;

export async function getCatalogsIndex(accessToken) {
  const response = await get(catalogsIndexUrl, accessToken);
  return { response, data: response?.json?.data, error: response.error };
}

export async function getOrganizationTypes(accessToken) {
  const response = await get(organizationTypesUrl, accessToken);
  return { response, data: response?.json?.data, error: response.error };
}

export async function getArchetypes(accessToken) {
  const response = await get(archetypesUrl, accessToken);
  return { response, data: response?.json?.data, error: response.error };
}

export async function getDataLinkTypes(accessToken) {
  const response = await get(dataLinkTypesUrl, accessToken);
  return { response, data: response?.json?.data, error: response.error };
}

export async function getSubscriptionTypes(accessToken) {
  const response = await get(subscriptionTypesUrl, accessToken);
  return { response, data: response?.json?.data, error: response.error };
}

export async function getOrganizationCategories(accessToken) {
  const response = await get(organizationCategoriesUrl, accessToken);
  return { response, data: response?.json, error: response.error };
}
