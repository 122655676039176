import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'formik';
import { Grid } from '@mui/material';
import {
  Divider,
  Dialog,
  DialogContent,
  DialogSectionTitle,
  DialogSubSectionText,
  DialogTitle,
  DialogActions,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';

import ToolTip from 'components/tooltip/tooltip';
import { SubscriptionSelector } from 'components/subscriptionSelectorV2/subscriptionSelector';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';
import { Typography } from 'components/typography/typography';
import style from './delegatedAccessUserModal.module.scss';

const DelegatedAccessAddNewUserModal = props => {
  const {
    isValid,
    dirty,
    isSubmitting,
    setFieldValue,
    touched,
    errors,
    initialValues,
    values,
    setFieldTouched,
    delegatedUserOptions,
  } = props;
  let history = useHistory();

  const handleClose = () => {
    // if the form is dirty, lets prompt the user that they will lose changes if they click discard changes
    history.push('/delegates');
  };

  const modifyingUser = initialValues?.selectUser?.id ?? false;
  const modifyingUserText = 'You are currently editing permmissions for ';
  const addUserText =
    'By completing this form, you are granting a Healthwise employee access to your organization’s data. You can revoke this user’s access at any time.';

  return (
    <Dialog
      aria-labelledby="createOrganizationSubscriptionModalTitle"
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      transitionDuration={500}
    >
      <Form>
        {!modifyingUser ? (
          <>
            <DialogTitle
              id="createOrganizationSubscriptionModalTitle"
              className={style.dialogTitle}
            >
              Add New Delegate
            </DialogTitle>
            <div className={style.warningBanner}>
              <Typography className={style.warningTextTypography}>
                {addUserText}
              </Typography>
            </div>
            <DialogContent className={style.dialogContent}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <DialogSectionTitle>
                      1. Select a Healthwise Employee
                    </DialogSectionTitle>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={`${style.itemGrid} ${style.rowField} ${
                      values.selectUser?.id
                        ? style.itemGridRowFieldPopulated
                        : ''
                    }`}
                  >
                    <SingleSelectInput
                      name="selectUser"
                      label={
                        values.selectUser?.id
                          ? ''
                          : 'List of Healthwise Employees'
                      }
                      variant="outlined"
                      optionsList={delegatedUserOptions}
                      touched={touched['selectUser']}
                      handleChange={setFieldValue}
                      handleBlur={setFieldTouched}
                      error={errors['selectUser']}
                      errorMsg={errors['selectUser']?.id}
                      helpMsg=""
                      selectedValue={values.selectUser}
                      disableCloseOnSelect={false}
                      disableClearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} className={style.gridItemDividr}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <DialogSectionTitle style={{ paddingTop: '10px' }}>
                      2. Assign Subscriptions and Roles
                    </DialogSectionTitle>
                  </Grid>
                  <Grid item>
                    <ToolTip
                      style={{ top: '-12px', marginLeft: '0.25em' }}
                      PopperProps={{
                        className: `${style.subscriptionTooltip} MuiTooltip-popper`,
                      }}
                      title="To grant a Healthwise employee access to your products, you need to assign them to a subscription and application. Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test."
                      buttonSize="medium"
                    />
                  </Grid>
                  <Grid item>
                    <DialogSubSectionText>
                      At least one Subscription, Application, and Role must be
                      assigned to grant delegated access.
                    </DialogSubSectionText>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={style.itemGrid}>
                  <SubscriptionSelector
                    compact={true}
                    allowMultiple={true}
                    {...props}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle
              id="editOrganizationSubscriptionModalTitle"
              className={style.dialogTitle}
            >
              Edit Delegate Access
            </DialogTitle>
            <div className={style.warningBanner}>
              <Typography className={style.warningTextTypography}>
                {modifyingUserText}
                <strong>{initialValues?.selectUser?.name}</strong>.
              </Typography>
            </div>
            <div className={style.message}>
              <p>
                At least one Subscription, Application, and Role must be
                assigned to enable delegated access.
              </p>
            </div>
            <DialogContent className={style.dialogContent}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12} className={style.itemGrid}>
                  <SubscriptionSelector
                    compact={true}
                    allowMultiple={true}
                    {...props}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <GeneralCancelButton
            handleClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </GeneralCancelButton>
          <GreenGeneralButton
            variant="contained"
            type="submit"
            disabled={!(isValid && dirty) || isSubmitting}
            buttonText={modifyingUser ? 'Save Changes' : 'Grant Access'}
          ></GreenGeneralButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default DelegatedAccessAddNewUserModal;
