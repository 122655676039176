export const getAssetsToProvision = assets => {
  return Object.entries(assets)
    .map(asset => {
      const [key, value] = asset;
      const { add } = value;
      return {
        [key]: {
          add: add?.map(item => {
            const { concepts } = item;
            if (concepts) {
              return { ...item, concepts: [] };
            } else {
              return item;
            }
          }),
        },
      };
    })
    .reduce((assetsObj, asset) => {
      const [key, value] = Object.entries(asset)[0];
      assetsObj[key] = value;
      return assetsObj;
    }, {});
};

export const getApplicationsToProvision = (module, app) => {
  const { applications, solutionLevel, id: moduleId } = module;
  return applications?.map(({ id, version, name, features }) => {
    const { base } = features;
    let optional = [];
    if (app?.id === id) {
      optional = features?.optional.filter(opt =>
        app?.features?.optional.some(x => x.id === opt.id),
      );
    }
    return {
      id,
      version: version ?? '1',
      name,
      solutionLevel,
      modules: [
        {
          id: moduleId,
        },
      ],
      features: {
        optional,
        base,
      },
    };
  });
};

export const getSelectedApplications = applications => {
  return Object.values(applications ?? {})?.filter(app => app !== null) ?? [];
};

export const getSelectedAssets = assets => {
  return Object.entries(assets ?? {}).reduce((assetsObj, asset) => {
    const [key, value] = asset;
    if (value !== null) {
      assetsObj[key] = value;
    }
    return assetsObj;
  }, {});
};

export const getSelectedApplicationsIds = selectedApplications =>
  selectedApplications?.map(app => app.id);

export const getApplicationsToProvisionWithoutSelectedApplication = (
  applicationsToProvision,
  selectedApplicationsIds,
) => {
  return applicationsToProvision.filter(
    app => !selectedApplicationsIds.includes(app.id),
  );
};

export const getSelectedModuleApplications = modules => {
  return Object.values(modules ?? {}).reduce((appArray, app) => {
    return appArray.concat(Object.values(app));
  }, []);
};

export const getSelectedAvailableModules = (
  licenseApplications,
  availableModules,
) => {
  return licenseApplications.reduce((moduleObj, app) => {
    const { modules } = app;
    modules.forEach(module => {
      const { id: moduleId } = module;

      const availModule = availableModules.find(x => x.id === moduleId);
      if (availModule) {
        const availApps = getApplicationsToProvision(availModule, app);

        let apps = availApps.map(app => {
          const { id } = app;
          return {
            [id]: {
              ...app,
            },
          };
        });

        const combineApps = Object.assign({}, ...apps);

        if (apps) {
          moduleObj[moduleId] = {
            ...moduleObj[moduleId],
            ...combineApps,
          };
        }
      }
    });
    return moduleObj;
  }, {});
};

export const getSelectedAvailableAssets = (assets, availableAssets) => {
  return Object.entries(assets ?? {}).reduce((assetsObj, asset) => {
    const [key, value] = asset;
    const availValue = availableAssets[key];

    if (value !== null) {
      value.add[0].concepts = availValue.add[0].concepts.filter(function (o1) {
        return value.add[0].concepts.some(function (o2) {
          return o1.conceptId === o2.conceptId;
        });
      });
      assetsObj[key] = value;
    }
    return assetsObj;
  }, {});
};
