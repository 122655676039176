import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@mui/material';
import { InfoIcon } from 'components/icons/icons';

const ToolTip = ({ title, buttonSize, ...props }) => {
  let btnSize = buttonSize === 'medium' ? 'medium' : 'small';
  return (
    <Tooltip title={title} {...props}>
      <IconButton
        variant="contained"
        color="primary"
        size={btnSize}
        style={{ marginLeft: '10px' }}
      >
        <InfoIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

ToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
};

export default ToolTip;
