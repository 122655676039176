import React, { useState, useEffect } from 'react';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  DialogSubSectionText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import { EditIcon } from 'components/icons/icons';
import { NotificationContext } from 'contexts/notificationContext';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import style from './editOrganizationSubscriptionModal.module.scss';

const EditOrganizationSubscriptionModal = props => {
  const {
    closeModal,
    isValid,
    dirty,
    isSubmitting,
    handleReset,
    status,
    closeMenu,
  } = props;

  const [open, setOpen] = useState(false);
  const notificationContext = React.useContext(NotificationContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
    closeMenu();
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal.current.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  const { isError, message = null, date } = status ?? {};

  useEffect(() => {
    if (message) {
      notificationContext.showNotification(message, isError, 5000, date);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, message, date]);

  return (
    <>
      <MenuItem className={style.menuItem} onClick={handleOpen}>
        <ListItemIcon>
          <EditIcon style={{ fill: '#424242' }} />
        </ListItemIcon>
        <ListItemText primary="Rename Subscription" />
      </MenuItem>

      <Dialog
        aria-labelledby="createOrganizationSubscriptionModalTitle"
        aria-describedby="createOrganizationSubscriptionModalDescription"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle>Rename Subscription</DialogTitle>
          <DialogContent>
            <DialogSubSectionText>
              The subscription name is a unique client-friently name for
              managing and isolating sets {'\n'}
              of resources in a given environment.
            </DialogSubSectionText>
            <div className={style.name}>
              <BaselineTextInput
                id="subscriptionName"
                name="subscriptionName"
                margin="none"
                size="small"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton
              handleClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Rename Subscription"
            ></GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

EditOrganizationSubscriptionModal.propTypes = {
  identityProviderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      identityProviderId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  environmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subscriptionTypeId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditOrganizationSubscriptionModal;
