import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import queryString from 'query-string';
import { FeatureToggleProvider } from 'react-feature-toggles';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { AuthProvider, AuthContext } from 'contexts/authContext';
import CustomImplicitCallback from 'components/customImplicitCallback/customImplicitCallback';
import ReloadLogin from 'containers/reloadLogin';
import {
  HOME_PATH,
  REDIRECT_PATH,
  RELOAD_LOGIN_PATH,
} from 'utils/configuration/links';
import 'typeface-roboto';

import 'index.css';
import App from 'containers/app';
import * as serviceWorker from 'serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { OktaConfig, CallbackConfig } from 'utils/configuration/okta';
import * as FeatureToggleDictionary from 'utils/dictionary/featureToggles';

const ShowDevTools = process.env.REACT_APP_DEV_TOOLS ?? false;

const oktaClient = new OktaAuth({
  ...OktaConfig,
  restoreOriginalUri: null,
});

const SecurityBoundary = () => {
  const location = useLocation();
  const queryClient = new QueryClient();
  const [toggles, setToggles] = useState();
  const history = useHistory();
  const { customAuthHandler } = React.useContext(AuthContext);

  const onAuthRequired = async oktaAuth => {
    await customAuthHandler(oktaAuth, location.search);
  };

  const customRestoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      history.replace(
        toRelativeUrl(originalUri || '/', window.location.origin),
      );
    },
    [history],
  );

  useEffect(() => {
    let parsedFeatures =
      queryString.parse(location.search)?.features?.split(',') ?? [];

    if (parsedFeatures.length > 0) {
      if (parsedFeatures.includes('off')) {
        sessionStorage.removeItem('features');
      } else {
        sessionStorage.setItem('features', JSON.stringify(parsedFeatures));
      }
    }
    parsedFeatures = JSON.parse(sessionStorage.getItem('features')) ?? [];

    setToggles(
      Object.values(FeatureToggleDictionary).reduce((toggles, value) => {
        // Only set the toggle if the parsedFeature value is found in the
        // FeatureToggleDictionary
        toggles[value] = toggles[value] || parsedFeatures.includes(value);
        return toggles;
      }, {}),
    );
  }, [location.search, setToggles]);

  useEffect(() => {
    //scroll to id if there is A hash
    if (location.hash !== '') {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
  }, [location.pathname, location.hash, location.key]); // do this on route change

  // Without this if statement, the setToggles() call in the useEffect() method
  // will cause the application to rerender multiple times. This can cause duplicate
  // authentication loops to be started due to an apparent bug in one of the
  // Okta packages (okta-react@^6.4.1 or okta-auth-js@^5.10.1 at the time of this comment).
  // The bug does not seem present in okta-react@^3 but leaving this package multiple
  // major versions behind does not seem feasible compared to this workaround.
  // Please leave this in place until that issue is resolved.
  if (!toggles) {
    return null;
  }

  return (
    <Security
      {...OktaConfig}
      onAuthRequired={onAuthRequired}
      oktaAuth={oktaClient}
      restoreOriginalUri={customRestoreOriginalUri}
    >
      <QueryClientProvider client={queryClient}>
        <FeatureToggleProvider featureToggleList={toggles}>
          <Switch>
            <Route path={RELOAD_LOGIN_PATH} component={ReloadLogin} />
            <Route
              path={REDIRECT_PATH}
              render={() => <CustomImplicitCallback {...CallbackConfig} />}
            />
            <SecureRoute path={HOME_PATH} component={App} />
          </Switch>
          {ShowDevTools ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </FeatureToggleProvider>
      </QueryClientProvider>
    </Security>
  );
};

ReactDOM.render(
  <AuthProvider>
    <Router>
      <SecurityBoundary />
    </Router>
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
