import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const StyledCircularProgress = styled(CircularProgress)`
  color: ${props => props.theme.colors.stormy};
  padding: 12px;
`;
// const StyledCircularProgress = styled(CircularProgress)`
//   color: ${props => props.theme.colors.stormy};
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   margin-top: -0.75em;
//   margin-left: -0.75em;
// `;
/**
 * Renders a styled circular progress indicator.
 * @param {number} size The size of the indicator, defaults to '24'
 */
export const ProgressIndicator = props => {
  const { size = 24 } = props;
  return <StyledCircularProgress size={size} />;
};
