import {
  Button as MaterialButton,
  Dialog as MaterialDialog,
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  DialogActions as MaterialDialogActions,
  Divider as MaterialDivider,
} from '@mui/material';
import styled from 'styled-components';
import style from './dialog.module.scss';

export const CancelDialogButton = styled(MaterialButton)`
  opacity: 1;
  text-transform: none;
  letter-spacing: 0;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  font-size: 16px;
  height: 42px;
  padding: 11px 18px 10px 18px;
  color: ${props => props.theme.colors.primaryText};

  &.MuiButton-root:hover {
    background-color: ${props => props.theme.colors.white};
  }
`;

export const ConfirmDialogButton = props => (
  <MaterialButton {...props}>{props.children}</MaterialButton>
);

export const Dialog = props => (
  <MaterialDialog className={style.dialog} {...props}>
    {props.children}
  </MaterialDialog>
);

export const DialogTitle = props => (
  <MaterialDialogTitle className={style.dialogTitle} {...props}>
    {props.children}
  </MaterialDialogTitle>
);

export const DialogSectionTitle = props => (
  <div className={style.dialogSectionTitle} {...props}>
    {props.children}
  </div>
);

export const DialogContent = props => (
  <MaterialDialogContent className={style.dialogContent} {...props}>
    {props.children}
  </MaterialDialogContent>
);

export const DialogContentText = props => (
  <MaterialDialogContentText className={style.dialogContentText} {...props}>
    {props.children}
  </MaterialDialogContentText>
);

export const DialogSubSectionText = props => (
  <p className={style.dialogSubSectionText} {...props}>
    {props.children}
  </p>
);

export const DialogActions = props => (
  <MaterialDialogActions className={style.dialogActions} {...props}>
    {props.children}
  </MaterialDialogActions>
);

export const Divider = props => <MaterialDivider className={style.divider} />;
