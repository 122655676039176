import * as configuration from 'utils/configuration/applications';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const applicationsUrl = baseUrl + configuration.APPLICATIONS_ROOT;

export async function getApplications(skip = 0, top = 10, accessToken = null) {
  return await utils.getPaginatedResult(
    applicationsUrl,
    skip,
    top,
    accessToken,
  );
}

export async function createApplication(application, accessToken) {
  return await utils.createResource(applicationsUrl, application, accessToken);
}

export async function getApplication(applicationId, accessToken) {
  return await utils.getResource(
    `${applicationsUrl}/${applicationId}`,
    accessToken,
  );
}

export async function updateApplication(
  applicationId,
  application,
  etag,
  accessToken,
) {
  return await utils.updateResource(
    `${applicationsUrl}/${applicationId}`,
    application,
    etag,
    accessToken,
  );
}

export async function deleteApplication(applicationId, etag, accessToken) {
  return await utils.deleteResource(
    `${applicationsUrl}/${applicationId}`,
    etag,
    accessToken,
  );
}
