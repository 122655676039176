import * as configuration from 'utils/configuration/entitlement';
import * as organizations from 'utils/configuration/organizations';
import * as subscriptions from 'utils/configuration/subscriptions';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const organizationsUrl = baseUrl + organizations.ORGANIZATIONS_ROOT;

export async function getEntitlementPackageForOrganization(
  organizationId,
  accessToken,
) {
  let url = `${organizationsUrl}/${organizationId}/${configuration.ENTITLEMENT}`;
  return await utils.getResource(url, accessToken);
}

export async function getEntitlementPackageForOrganizationSubscription(
  organizationId,
  subscriptionId,
  accessToken,
) {
  let url = `${organizationsUrl}/${organizationId}/${subscriptions.SUBSCRIPTIONS}/${subscriptionId}/${configuration.ENTITLEMENT}`;
  return await utils.getResource(url, accessToken);
}
