import * as configuration from 'utils/configuration/userProfile';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}${configuration.USERPROFILE_ROOT}`;

/**
 * Lookup and retrieve the current user
 * @param {string} accessToken A valid access token
 */
export async function getUserProfile(accessToken) {
  return await utils.getResource(
    `${baseUrl}?api-version=${configuration.USERPROFILE_API_VERSION}`,
    accessToken,
  );
}

/**
 * Initiates the reset password flow for the current user
 * @param {string} accessToken A valid access token
 */
export async function resetPasswordProfile(accessToken) {
  return await utils.postWithContent(
    `${baseUrl}/passwordReset?api-version=${configuration.USERPROFILE_API_VERSION}`,
    undefined,
    accessToken,
  );
}
