import React, { useState, useContext, forwardRef, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Tooltip,
  ListItemIcon,
  MenuItem,
  Dialog,
  DialogContent,
  TextField,
} from '@mui/material';
import {
  RedButton,
  CancelButton,
  CloseIconButton,
  DoneButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import { LockResetIcon } from 'components/icons/icons';
import { AuthContext } from 'contexts/authContext';
import Notification from 'components/notificationV2/notification';
import { expirePassword } from 'utils/api/users';
import { forceResetPasswordOrganizationAdministrator } from 'utils/api/organizations';
import { BackdropLoader } from 'components/backdropLoader/backdropLoader';
import style from './forceResetPasswordWorkflow.module.scss';

const ForceResetPasswordWorkflow = forwardRef((props, ref) => {
  const { forOrgAdmin, userInfo, organization } = props;
  const authContext = useContext(AuthContext);

  const allowForceReset = ['ACTIVE'].includes(userInfo?.status);

  const [forceModalOpen, setForceModalOpen] = useState(false);
  const [tempModalOpen, setTempModalOpen] = useState(false);
  const [tempPassword, setTempPassword] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmInput, setConfirmInput] = useState('');
  const [resetBtnDisabled, setResetBtnDisabled] = useState(true);

  const handleForceModalOpen = () => setForceModalOpen(true);
  const handleForceModalClose = () => {
    setForceModalOpen(false);
    setResetBtnDisabled(true);
    setConfirmInput('');
  };
  const handleTempModalClose = () => setTempModalOpen(false);

  const handleConfirmPasswordReset = () => {
    const executePasswordReset = async (userId, accessToken) => {
      setIsSubmitting(true);
      setForceModalOpen(false);

      const { response, data, error } = forOrgAdmin
        ? await forceResetPasswordOrganizationAdministrator(
            organization.organizationId,
            userId,
            accessToken,
          )
        : await expirePassword(userId, accessToken);

      if (response.ok) {
        setTempPassword(data.temporaryPassword);
        setPasswordChanged(true);
        setTempModalOpen(true);
      } else {
        setError(
          `Unable to retrieve temporary password - ${error?.details?.error?.message}.`,
        );
      }
      setIsSubmitting(false);
    };

    const userId = userInfo.id ?? userInfo.userId;
    const accessToken = authContext?.accessToken;
    if (userId && accessToken) {
      executePasswordReset(userId, accessToken);
    }
  };

  const handleTextChange = e => {
    setConfirmInput(e.target.value);
  };

  useEffect(() => {
    if (confirmInput.toLowerCase() === 'confirm') setResetBtnDisabled(false);
    if (confirmInput.toLowerCase() !== 'confirm') setResetBtnDisabled(true);
  }, [confirmInput]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    allowForceReset && (
      <>
        {error ? <Notification error message={error} duration={8000} /> : null}
        {forOrgAdmin ? (
          <MenuItem tabIndex={0} onClick={handleForceModalOpen}>
            <ListItemIcon className={style.listItemIcon}>
              <LockResetIcon />
            </ListItemIcon>
            <Tooltip title="challenge question unknown">
              <span className={style.listItemText}>
                Emergency Password Reset
              </span>
            </Tooltip>
          </MenuItem>
        ) : (
          <>
            <p className={style.details}>
              This action is for users that have forgotten their secret question
              answer and are unable to fix their login using the standard reset.
            </p>
            <RedButton
              className={style.emergencyResetButton}
              buttonText={`Emergency Password Reset`}
              handleClick={handleForceModalOpen}
            />
          </>
        )}
        {isSubmitting ? (
          <BackdropLoader open={isSubmitting} />
        ) : forceModalOpen ? (
          <Dialog
            open={forceModalOpen}
            onClose={handleForceModalClose}
            className={style.dialog}
          >
            <h1 className={style.dialogHeading}>Emergency Password Reset</h1>
            <DialogContent className={style.dialogContent}>
              <p className={style.highlightedText}>
                This action is for users that have forgotten the answer to their
                secret question and are unable to fix their login using the
                standard reset.
              </p>
              <p className={style.modalText}>
                Emergency Password Reset will generate a temporary password that
                you will need to share with the user. This should be used as a
                last resort to help users reset their password.
              </p>
              <p className={style.modalText}>
                Are you sure you want to perform an Emergency Password Reset for{' '}
                <span className={style.name}>
                  {userInfo?.firstName} {userInfo?.lastName}
                </span>
                ? Type 'confirm' below.
              </p>
              <div className={style.textFieldContainer}>
                <TextField
                  className={style.textField}
                  value={confirmInput}
                  type="text"
                  name="confirm"
                  onChange={handleTextChange}
                  color="primary"
                  focused
                  size="small"
                  fullWidth
                  variant="filled"
                />
              </div>
              <div className={style.modalBtnsContainer}>
                <CancelButton
                  buttonText={`Cancel`}
                  handleClick={handleForceModalClose}
                />
                <RedButton
                  disabled={resetBtnDisabled}
                  buttonText={`Reset Password`}
                  handleClick={handleConfirmPasswordReset}
                  className={style.redBtn}
                />
              </div>
            </DialogContent>
          </Dialog>
        ) : passwordChanged ? (
          <Dialog open={tempModalOpen} onClose={handleTempModalClose}>
            <div className={style.dialogTitle}>
              <h1 className={style.dialogHeading}>Temporary Password</h1>
              <CloseIconButton
                onClick={handleTempModalClose}
                className={style.cancelButton}
              />
            </div>
            <DialogContent className={style.dialogContent}>
              <p className={style.modalText}>
                Share the temporary password below with{' '}
                <span className={style.name}>
                  {userInfo?.firstName} {userInfo?.lastName}
                </span>{' '}
                at {userInfo?.email} as soon as possible.
              </p>
              <p className={style.modalText}>
                Upon login using the temporary password, the user will be
                prompted to create a new password.
              </p>
              <div className={style.copyToClipboardContainer}>
                <span className={style.tempPassword}>{tempPassword}</span>
                <CopyToClipboard
                  text={tempPassword}
                  onCopy={onCopyText}
                  key="clip"
                >
                  {isCopied ? (
                    <DoneButton className={style.copyDoneButton} key="copy" />
                  ) : (
                    <GreenGeneralButton
                      buttonText="Copy"
                      key="copy"
                      className={style.flatBottomButton}
                    />
                  )}
                </CopyToClipboard>
              </div>
            </DialogContent>
          </Dialog>
        ) : null}
      </>
    )
  );
});

export default ForceResetPasswordWorkflow;
