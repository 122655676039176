import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { InputAdornment, TextField } from '@mui/material';

import { ErrorIcon, SuccessIcon } from 'components/icons/icons';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';

// TODO Implement: https://healthwise.atlassian.net/wiki/spaces/DP/pages/1513095584/Text+Field

// Only way I could figure out how to implement the dashed line, no way to talk
// to the .Mui components that wrap the TextField.
const MuiWrapper = styled.div`
  margin: 1em 1em 1em 0;
  :focus-within {
    outline-offset: 2px;
    outline: 2px dotted ${props => props.theme.colors.black};
  }
`;

// TODO Cannot figure out how to apply the border-radius
const MuiTextField = styled(TextField)`
  overflow: hidden;
  border-radius: 4 4 0 0;

  .MuiFilledInput-underline:before {
    border-bottom: 2px solid ${props => props.theme.colors.darkestGrey};
  }
  .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${props => props.theme.colors.black};
  }

  .MuiFormHelperText-root {
    color: ${props => props.theme.colors.darkestGrey};
  }
  .MuiFormHelperText-root.Mui-focused {
    color: ${props => props.theme.colors.black};
  }
  .MuiFormHelperText-root.Mui-error {
    color: ${props => props.theme.colors.error};
  }

  .MuiInputLabel-root {
    color: ${props => props.theme.colors.darkestGrey};
  }
  .MuiInputLabel-root.Mui-focused {
    color: ${props => props.theme.colors.black};
  }
  .MuiInputLabel-root.Mui-error {
    color: ${props => props.theme.colors.error};
  }

  .MuiFilledInput-root {
    transform: none;
    background-color: ${props => props.theme.colors.background};
  }
  .MuiFilledInput-root:focus {
    background-color: ${props => props.theme.colors.backgroundDark};
  }
  .MuiFilledInput-root:hover {
    background-color: ${props => props.theme.colors.backgroundDark};
  }
`;

// Non-formik controlled component
export const BaselineTextField = props => {
  // Set 'our' defaults so that they can be overriden
  const { fullWidth = true, variant = 'filled' } = props;
  return (
    <MuiWrapper>
      <MuiTextField fullWidth={fullWidth} variant={variant} {...props} />
    </MuiWrapper>
  );
};

const determineEndStatusIcon = (isSubmitting, meta) => {
  if (meta.touched) {
    if (isSubmitting) {
      return (
        <InputAdornment position="start">
          <ProgressIndicator />
        </InputAdornment>
      );
    } else if (meta.error) {
      return (
        <InputAdornment position="start">
          <ErrorIcon fontSize="small" />
        </InputAdornment>
      );
    } else {
      // default success
      return (
        <InputAdornment position="start">
          <SuccessIcon fontSize="small" />
        </InputAdornment>
      );
    }
  } else {
    return null;
  }
};

export const BaselineTextInput = props => {
  const { formikprops } = props;
  const [field, meta] = useField(props);

  const isSubmitting = formikprops?.isSubmitting ?? false;
  const endAdornment = determineEndStatusIcon(isSubmitting, meta);

  return (
    <BaselineTextField
      {...field}
      {...props}
      error={meta.touched && meta.error ? true : false}
      helperText={meta.touched && meta.error ? meta.error : null}
      InputProps={{
        endAdornment: endAdornment,
      }}
    />
  );
};

export const BaselineTextInputv2 = props => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...field}
      {...props}
      error={meta.touched && meta.error ? true : false}
      helperText={meta.touched && meta.error ? meta.error : null}
      variant="filled"
    />
  );
};
