import React from 'react';
import { Form } from 'formik';
import { Button as MaterialButton } from '@mui/material';
import { NotificationContext } from 'contexts/notificationContext';
import RoleAssignment from './components/roleAssignment/roleAssignment';
import { UserDetails } from './components/userDetails/userDetails';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';

import {
  UserCardv2 as UserCard,
  UserCardHeaderv2 as UserCardHeader,
  UserCardContentv2 as UserCardContent,
} from 'components/card/card';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { BackToPrevPageLink } from 'components/users/backToPrevPageLink';
import { USER_MANAGEMENT_PATH } from 'utils/configuration/links';

import style from './createUserForm.module.scss';

const InviteUser = props => {
  const { isSubmitting, isValid, dirty, values, actions, handleSubmit } = props;

  return (
    <>
      {isSubmitting ? (
        <ProgressIndicator />
      ) : (
        <MaterialButton
          className={style.submitFormButton}
          disabled={!isValid || !dirty}
          type="submit"
          variant="contained"
          onClick={() => handleSubmit(values, actions)}
        >
          Send Invitation
        </MaterialButton>
      )}
    </>
  );
};

const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
});
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

const ActionResponse = props => {
  const notificationContext = React.useContext(NotificationContext);
  const { status, values } = props;

  React.useEffect(() => {
    if (status) {
      let message = '';
      let isError = false;
      if (status.success) {
        const date = Date.now();
        const dateString = `${timeFormatter.format(
          date,
        )} on ${dateFormatter.format(date)}`;
        message = `Invitation emailed to ${values.firstName} ${values.lastName} at ${dateString}. This user has 10 days to activate their account.`;
      } else {
        message = `Error creating user: ${status?.error?.error?.message}. Please try again or contact your Healthwise administrator.`;
        isError = true;
      }

      notificationContext.showNotification(
        message,
        isError,
        10000,
        status.lastSetDate,
      );
    }
  }, [status, notificationContext, values.firstName, values.lastName]);

  return null;
};

const CreateUserForm = props => {
  const {
    values: { selectedRoles },
    validateForm,
  } = props;

  // Sometimes Formik's "isValid" prop is still incorrectly true after a role field
  // is no longer valid. This will force a reevaluation of the form fields
  // if the "selectedRoles" value changes.
  React.useEffect(() => {
    validateForm();
  }, [selectedRoles, validateForm]);

  return (
    <>
      <ActionResponse {...props} />
      <Form>
        <BackToPrevPageLink
          text="Back to User Management"
          path={USER_MANAGEMENT_PATH}
        />
        <UserCard id="createUserFormUserCard">
          <UserCardHeader
            title="Create New User"
            className={style.cardHeader}
          />
          <UserCardContent>
            <UserDetails {...props} creating={true} />

            <HorizontalRule />

            <RoleAssignment
              tooltipText="To create a user, you need to assign them to a subscription and role(s) in the application. Roles and permissions are grouped by subscriptions and applications that your company licenses. Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test."
              {...props}
            />

            <HorizontalRule />
            <div className={style.submitFormButtonContainer}>
              <InviteUser {...props} />
            </div>
          </UserCardContent>
        </UserCard>
      </Form>
    </>
  );
};

export default CreateUserForm;
