import * as configuration from 'utils/configuration/applications';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const applicationsUrl = baseUrl + configuration.APPLICATIONS_ROOT;

export async function createRole(applicationId, role, accessToken) {
  let createUrl = `${applicationsUrl}/${applicationId}/roles`;
  return await utils.createResource(createUrl, role, accessToken);
}

export async function deleteRole(applicationId, roleId, etag, accessToken) {
  let deleteUrl = `${applicationsUrl}/${applicationId}/roles/${roleId}`;
  return await utils.deleteResource(deleteUrl, etag, accessToken);
}

export async function updateRole(
  applicationId,
  roleId,
  role,
  etag,
  accessToken,
) {
  let updateUrl = `${applicationsUrl}/${applicationId}/roles/${roleId}`;
  return await utils.updateResource(updateUrl, role, etag, accessToken);
}

export async function getRole(applicationId, roleId, accessToken) {
  let getRoleUrl = `${applicationsUrl}/${applicationId}/roles/${roleId}`;
  return await utils.getResource(getRoleUrl, accessToken);
}

export async function getRoles(applicationId, accessToken) {
  let getRolesUrl = `${applicationsUrl}/${applicationId}/roles?top=100`;
  return await utils.getResource(getRolesUrl, accessToken);
}
