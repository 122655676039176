import React from 'react';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { v4 } from 'uuid';
import queryString from 'query-string';
import {
  ORGANIZATION_ADMINISTRATOR_ROLE,
  PROVISIONING_MANAGER_ROLE,
} from 'utils/dictionary/overview';
import { LOGIN_PATH, LOGOUT_PATH } from 'utils/configuration/links';
import { OktaConfig, CallbackConfig } from 'utils/configuration/okta';

export const AuthContext = React.createContext();

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

const getSubscriptionIdFromToken = token => {
  let scopes = parseJwt(token).scp;
  let subscription;
  // get subscription
  for (let i = 0; i < scopes.length; i++) {
    // within our scopes there should only be one identifier that has a
    // two part value. This two part value should be the selected subscription and org
    let tempResult = scopes[i].split('::');
    if (tempResult.length > 1) {
      // lets check if what we've split contains the organization id.
      // since we are following the org::sub
      subscription = tempResult[1];
    }
  }
  return subscription;
};

const getOrgIdFromToken = token => {
  let organizationId = parseJwt(token).organizationId;
  let scopes = parseJwt(token).scp;
  // get organization
  for (let i = 0; i < scopes.length; i++) {
    // within our scopes there should only be one identifier that has a
    // two part value. This two part value should be the selected subscription and org
    let tempResult = scopes[i].split('::');
    if (tempResult.length > 1) {
      // lets check if what we've split contains the organization id.
      // since we are following the org::sub
      organizationId = tempResult[0];
    }
  }
  return organizationId;
};

const getProperRole = roles => {
  // if a null roles array is supplied will fall through to return null.
  // set provisioning manager role at highest level.
  if (roles.includes(PROVISIONING_MANAGER_ROLE)) {
    return PROVISIONING_MANAGER_ROLE;
  }
  // user does not have a provisioning manager role as part of roles.
  // but has org admin.
  if (roles.includes(ORGANIZATION_ADMINISTRATOR_ROLE)) {
    return ORGANIZATION_ADMINISTRATOR_ROLE;
  }
  return null;
};

// TODO: limit refine role distribution so this method can contain less logic.
const getOrgRoleFromToken = token => {
  if (token) {
    // declare role array
    let rolesArray = [];
    try {
      // split roles into array
      let roles = parseJwt(token).roles;
      let org = getOrgIdFromToken(token);
      // iterate through array and parse out role names
      for (let i = 0; i < roles.length; i++) {
        let parts = roles[i].split('::');
        if (parts.length !== 4) continue;
        if (
          parts[0] === org &&
          parts[2] === process.env.REACT_APP_OKTA_CLIENT_ID
        ) {
          rolesArray.push(parts[3]);
        }
      }
      // check if provisioning manager role exists otherwise return org admin or null
      return getProperRole(rolesArray);
    } catch (error) {
      // TODO  Render error to user
      //console.log('role err: ' + error);
    }
  }
  return null;
};

const getAdminOrgsFromToken = token => {
  let adminOrgs = [];
  if (token) {
    try {
      let roles = parseJwt(token).roles;
      // iterate through roles array and parse out role names
      for (let i = 0; i < roles.length; i++) {
        let parts = roles[i].split('::');
        if (parts.length !== 4) continue;
        if (parts[3] === ORGANIZATION_ADMINISTRATOR_ROLE) {
          const adminOrgsSub = {
            organizationId: parts[0],
            subscriptionId: parts[1],
          };
          adminOrgs.push(adminOrgsSub);
        }
      }
    } catch (error) {
      // Error parsing the jwt, bail.
      return [];
    }
  }
  return adminOrgs;
};
/**
 * Retrieve only the management roles from the JWT
 */
const getManagementRolesFromToken = token => {
  let managementRoles = [];

  if (token) {
    try {
      // split roles into array
      let roles = parseJwt(token).roles;
      let org = getOrgIdFromToken(token);
      // iterate through array and parse out role names
      for (let i = 0; i < roles.length; i++) {
        let parts = roles[i].split('::');
        if (parts.length !== 4) continue;
        if (
          parts[0] === org &&
          parts[2] === process.env.REACT_APP_OKTA_CLIENT_ID
        ) {
          managementRoles.push(parts[3]);
        }
      }
    } catch (error) {
      // Error parsing the jwt, bail.
      return [];
    }
  }

  return managementRoles;
};

const customAuthHandler = async (oktaAuth, locationSearch) => {
  const { subscription_id } = queryString.parse(locationSearch);

  // if newState is undefined, the Okta logic should generate a state value out of a random string
  const newState = subscription_id
    ? btoa(
        JSON.stringify({
          subscription_id,
          salt: v4(),
        }),
      )
    : undefined;

  await oktaAuth.signInWithRedirect({
    ...OktaConfig,
    issuer: CallbackConfig.frontDoorUri,
    state: newState,
  });
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = React.useReducer((oldState, newState) => newState, {
    loading: true,
    token: undefined,
    permissions: null,
    user: null,
    managementRoles: [],
    customAuthHandler,
  });

  const updateAuth = async authState => {
    const token = authState?.idToken?.idToken;
    const accessToken = authState?.accessToken?.accessToken;
    if (token && token !== state.token && accessToken !== state.accessToken) {
      try {
        setState({
          token,
          organizationId: getOrgIdFromToken(accessToken),
          subscriptionId: getSubscriptionIdFromToken(accessToken),
          accessToken: accessToken, // Added, previously when destructuring returned null
          permissions: parseJwt(accessToken),
          loading: false,
          user: parseJwt(token),
          managementRoles: getManagementRolesFromToken(accessToken),
          orgRole: getOrgRoleFromToken(accessToken),
          isProvisioningManager:
            getOrgRoleFromToken(accessToken) === PROVISIONING_MANAGER_ROLE,
          adminOrgs: getAdminOrgsFromToken(accessToken),
          customAuthHandler,
        });
      } catch (error) {
        // TODO Render error to user
        // console.log('Issue parsing token', error);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHandler = () => {
  const { updateAuth, customAuthHandler } = React.useContext(AuthContext);
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();

  React.useEffect(() => {
    updateAuth(authState);
  });

  React.useEffect(() => {
    if (location.pathname === LOGIN_PATH) {
      customAuthHandler(oktaAuth, location.search);
    }

    if (location.pathname === LOGOUT_PATH) {
      oktaAuth.signOut();
      sessionStorage.removeItem('features');
    }
  }, [
    authState,
    oktaAuth,
    location.pathname,
    customAuthHandler,
    location.search,
  ]);

  return null;
};

export const AuthConsumer = AuthContext.Consumer;
