import { SUBSCRIPTIONS } from 'utils/configuration/subscriptions';
import { ORGANIZATIONS_ROOT } from 'utils/configuration/organizations';
import {
  PROVISIONING_START,
  PROVISIONING,
} from 'utils/configuration/provisioning';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const organizationsUrl = baseUrl + ORGANIZATIONS_ROOT;

export async function configureProductFeaturesAndContent(
  organizationId,
  subscriptionId,
  provisioning,
  accessToken,
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}/${PROVISIONING_START}`,
    provisioning,
    accessToken,
  );
}

export async function provisioning(
  organizationId,
  subscriptionId,
  provisioning,
  accessToken,
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}/${PROVISIONING}`,
    provisioning,
    accessToken,
  );
}
