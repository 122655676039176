import React from 'react';
import { Grid } from '@mui/material';

import { FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { GreenGeneralButton } from 'components/buttons/button';
import { SubscriptionSelectorRow } from 'components/subscriptionSelectorRow/subscriptionSelectorRow';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';

import { useReferenceSubscriptions } from 'contexts/referenceSubscriptionsContext';

import style from './subscriptionSelector.module.scss';

// Get a set of callbacks to pass to individual SubscriptionSelectorRow components
// without needing to pass the Formik FieldArray arrayHelper all the way down
const getRowHandlers = (arrayHelper, handleRowChange, handleRowRemoved) => {
  // Handle changes in the selected roles
  const roleChangeHandler = (value, subscriptionId, applicationId, row) => {
    let selectedRoles = arrayHelper.form.values.selectedRoles;

    // Save off all of the roles that don't belong to our sub/app.
    let keptRoles = selectedRoles.filter(role => {
      return (
        subscriptionId !== role.subscriptionId ||
        applicationId !== role.applicationId
      );
    });

    // Expand the roles for our sub/app.
    keptRoles = [...keptRoles, ...value];

    arrayHelper.form.setFieldValue('selectedRoles', keptRoles, true);
    row.roles = value;

    handleRowChange(row);
  };

  // Handle changes in the selected application
  const applicationChangeHandler = (value, row) => {
    // If the application changes, we need to deselect any selected roles
    roleChangeHandler([], row.subscription?.id, row.application?.id, row);

    row.application = value;
    row.applicationId = value?.id;
    row.roles = [];
    handleRowChange(row);
  };

  const subscriptionChangeHandler = (value, row) => {
    // If the subscription changes, we need to deselect any selected roles and application
    roleChangeHandler([], row.subscription?.id, row.application?.id, row);

    row.subscription = value;
    row.subscriptionId = value?.id;
    row.application = undefined;
    row.applicationId = undefined;
    row.roles = [];
    handleRowChange(row);
  };

  const rowRemovedHandler = row => {
    if (row.subscription?.id && row.application?.id) {
      roleChangeHandler([], row.subscription.id, row.application.id, row);
    }

    handleRowRemoved(row);
  };

  return {
    roleChangeHandler,
    applicationChangeHandler,
    subscriptionChangeHandler,
    rowRemovedHandler,
  };
};

// Take the raw subscription/application/role objects and
// nest the subscription and application ids through the
// object tree. This will make handling them easier.
const getSubscriptionOptions = subscriptions => {
  if (!subscriptions) {
    return [];
  }

  return subscriptions.map(sub => {
    var { applications, ...subFields } = sub;

    // Eliminate any duplicate application entries
    const uniqueIds = new Set(applications.map(a => a.id));
    const uniqueApplications = [...uniqueIds].map(id => {
      return applications.find(a => a.id === id);
    });

    return {
      ...subFields,
      applications: uniqueApplications.map(app => {
        var { roles, ...appFields } = app;
        return {
          ...appFields,
          subscriptionId: subFields.id,
          roles: roles.map(role => {
            const { id, name, value } = role;
            return {
              id,
              name,
              value,
              subscriptionId: subFields.id,
              applicationId: appFields.id,
            };
          }),
        };
      }),
    };
  });
};

export const SubscriptionSelector = props => {
  const { referenceSubscriptions } = useReferenceSubscriptions();
  const subscriptionOptions = getSubscriptionOptions(referenceSubscriptions);
  const [rows, setRows] = React.useState([]);

  const {
    allowMultiple = true,
    values: { selectedRoles },
    className,
    setAllRowsComplete,
  } = props;

  // Prepopulate the "rows" value if "selectedRoles" is populated
  React.useEffect(() => {
    if (rows.length > 0 || !selectedRoles || selectedRoles.length < 1) {
      return;
    }

    // Group the roles by subscription and application
    const groups = selectedRoles.reduce((groups, item) => {
      const groupKey = `${item.subscriptionId}::${item.applicationId}`;
      const group = groups[groupKey] || {
        subscriptionId: item.subscriptionId,
        applicationId: item.applicationId,
        roles: [],
      };
      group.roles.push(item);
      groups[groupKey] = group;
      return groups;
    }, {});

    // Assemble row objects based on the groups above
    const tempRows = Object.keys(groups)
      .map(groupName => {
        const group = groups[groupName];

        // Get a matching subscription from the valid options
        const subscription = subscriptionOptions.find(
          sub => sub.id === group.subscriptionId,
        );

        // If no matching subscription is found, exit
        if (!subscription) {
          return undefined;
        }

        // Get a matching application from the valid options
        const application = subscription.applications.find(
          app => app.id === group.applicationId,
        );

        // If no matching application is found, exit
        if (!application) {
          return undefined;
        }

        // Get the matching roles from the valid options
        const roles = application.roles.filter(appRole => {
          return group.roles.findIndex(gr => gr.id === appRole.id) >= 0;
        });

        // If no matching roles are found, exit
        if (roles.length < 1) {
          return undefined;
        }

        // Create a row object
        return {
          id: uuidv4(),
          subscription,
          subscriptionId: subscription?.id,
          application,
          applicationId: application?.id,
          roles,
        };
      })
      .filter(item => !!item);

    setRows(tempRows);
  }, [selectedRoles, rows.length, subscriptionOptions]);

  const handleRowChange = row => {
    if (!row?.id) {
      // new row
      row.id = uuidv4();
      rows.push(row);
    } else {
      const index = rows.findIndex(r => r.id === row.id);
      if (index !== undefined) {
        rows[index] = row;
      } else {
        rows.push(row);
      }
    }

    setRows([...rows]);
  };

  const handleRowRemoved = row => {
    setRows(rows.filter(i => i.id !== row.id) || []);
  };

  // Only allow a new row to be added if all existing rows have at least 1 role selected
  const allRowsComplete = rows.reduce(
    (previous, current) => previous && current.roles?.length > 0,
    true,
  );

  if (setAllRowsComplete) {
    setAllRowsComplete(allRowsComplete);
  }

  // Don't allow a new row to be added if there are no unused subscription + application combinations
  const canAddRow = subscriptionOptions.reduce((previous, sub) => {
    const applications = sub.applications.reduce((previousApp, app) => {
      // Loop through the usedRows and check if one matches the current sub + app
      for (let i = 0; i < rows.length; i++) {
        const currentRow = rows[i];

        if (
          currentRow.subscriptionId === sub.id &&
          currentRow.applicationId === app.id
        ) {
          // Found a match
          return previousApp || false;
        }
      }

      // If we're returning true here, it means we didn't find this sub + app in the usedRows,
      // so we should be able to add a new row to the list
      return true;
    }, false);

    return previous || applications;
  }, false);

  return (
    <>
      <Grid
        className={`${style.addSubscriptionSection} ${className}`}
        container
      >
        <FieldArray
          name="subscriptions"
          render={arrayHelper => {
            const callbacks = {
              ...getRowHandlers(arrayHelper, handleRowChange, handleRowRemoved),
            };

            if (arrayHelper?.form?.values) {
              return (
                <>
                  {rows.map((row, index, { length: arrayLength }) => {
                    // Filter this row's options to remove subscriptions + application combinations already used by other rows
                    const subscriptionFilteredOptions = subscriptionOptions
                      .map(sub => {
                        return {
                          ...sub,
                          applications: sub.applications.filter(app => {
                            // Loop through all the rows and check if they already have used this sub + app combination
                            for (let i = 0; i < rows.length; i++) {
                              const currentRow = rows[i];

                              // If this subscription and application have been used by another row,
                              // filter the application from this subscription's application array.
                              if (
                                currentRow.id !== row.id &&
                                currentRow.subscriptionId === sub.id &&
                                currentRow.applicationId === app.id
                              ) {
                                return false;
                              }
                            }

                            return true;
                          }),
                        };
                      })
                      .filter(sub => {
                        // If all this subscription's applications have been filtered out, filter out the subscription entirely
                        return sub.applications.length > 0;
                      });

                    // Check if this row's selected subscription is already used by another row
                    const isSubscriptionInOptionsList =
                      !row.subscription ||
                      subscriptionFilteredOptions.reduce((previous, sub) => {
                        return previous || row.subscription.id === sub.id;
                      }, false);

                    if (!isSubscriptionInOptionsList) {
                      // Reset this row to avoid conflicting with another row that already used this subscription
                      callbacks.subscriptionChangeHandler(null, row);
                      return null;
                    }

                    const currentSubscriptions =
                      subscriptionFilteredOptions.filter(
                        sub => sub.id === row.subscription?.id,
                      );
                    const currentSubscription =
                      currentSubscriptions.length > 0
                        ? currentSubscriptions[0]
                        : row.subscription;

                    return (
                      <React.Fragment key={row.id}>
                        <SubscriptionSelectorRow
                          row={row}
                          index={row.id}
                          subscriptions={subscriptionFilteredOptions}
                          selectedSubscription={currentSubscription}
                          selectedApplication={row.application}
                          selectedRoles={row.roles}
                          {...callbacks}
                          {...props}
                        />
                        {allowMultiple && index !== arrayLength - 1 ? (
                          <HorizontalRule />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  {rows.length > 0 ? (
                    allowMultiple && allRowsComplete && canAddRow ? (
                      <>
                        <HorizontalRule />
                        <GreenGeneralButton
                          className={`${style.addAnotherButton} ${
                            props.compact ? 'compact' : ''
                          }`}
                          onClick={() => {
                            handleRowChange({});
                          }}
                          buttonText="Add another subscription or application"
                        />
                      </>
                    ) : null
                  ) : (
                    <SubscriptionSelectorRow
                      row={{}}
                      subscriptions={subscriptionOptions}
                      {...callbacks}
                      {...props}
                    />
                  )}
                </>
              );
            } else {
              return (
                <SubscriptionSelectorRow
                  row={{}}
                  subscriptions={subscriptionOptions}
                  {...callbacks}
                  {...props}
                />
              );
            }
          }}
        />
      </Grid>
    </>
  );
};

export default SubscriptionSelector;
