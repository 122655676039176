import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Link as MaterialLink } from '@mui/material';
import { PermIdentity, HelpOutline } from '@mui/icons-material';
import { USER_PROFILE_PATH } from 'utils/configuration/links';
import {
  USER_MENU_MANAGE_BUTTON_TEXT,
  USER_MENU_GET_HELP_TEXT,
  USER_MENU_SIGN_OUT_TEXT,
  USER_MENU_MYHW_BUTTON_TEXT,
} from 'utils/dictionary/overview';
import { LOGOUT_PATH } from 'utils/configuration/links';
import { AppsIcon, LogoutIcon } from 'components/icons/icons';
import style from './userInfo.module.scss';

const UserInfo = ({ user, handleClose }) => {
  const { name, email } = user;

  return (
    <div className={style.styledDiv}>
      <p className={style.hdName}>
        <strong>{name}</strong>
      </p>
      {email ? <p className={style.email}>{email}</p> : ''}

      <div className={style.myHWDiv}>
        <AppsIcon className={style.appsIcon} />
        <MaterialLink
          className={style.hyperLinks}
          onClick={handleClose}
          href={process.env.REACT_APP_FRONTDOOR_ISSUER}
        >
          {USER_MENU_MYHW_BUTTON_TEXT}
        </MaterialLink>
      </div>

      <Divider className={style.dividr} variant="middle" />

      <div className={style.menuDiv}>
        <PermIdentity className={style.icons} />
        <MaterialLink
          className={style.hyperLinks}
          onClick={handleClose}
          href={USER_PROFILE_PATH}
        >
          {USER_MENU_MANAGE_BUTTON_TEXT}
        </MaterialLink>
      </div>
      <div className={style.menuDiv}>
        <HelpOutline className={style.icons} />
        <MaterialLink className={style.hyperLinks} component={Link} to="">
          {USER_MENU_GET_HELP_TEXT}
        </MaterialLink>
      </div>
      <Divider className={style.dividr} variant="middle" />
      <div className={style.menuDiv}>
        <LogoutIcon className={style.signoutIcon} />
        <MaterialLink
          className={style.signOutLink}
          component={Link}
          to={LOGOUT_PATH}
        >
          {USER_MENU_SIGN_OUT_TEXT}
        </MaterialLink>
      </div>
    </div>
  );
};

export default UserInfo;
