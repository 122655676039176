import React from 'react';
import { Grid, Box, Divider, CardContent, Typography } from '@mui/material';
import { DATE_ONLY } from 'utils/dictionary';
import { FormatISO } from 'utils/timeAndDate';
import ToolTip from 'components/tooltip/tooltip';
import style from './salesforceDetailPane.module.scss';
import DeleteOrganization from 'containers/organizationManagement/deleteOrganization';
import { HEALTHWISE_ORG } from 'utils/dictionary/overview';

// Emit data with associated header. If the data contains elements that are
//   undefined, a <skeleton> for that item
const DisplayData = ({ header, data }) => {
  let items = [].concat(data);

  return (
    <Box className={style.dataContent}>
      <Typography className={style.header}>{header}</Typography>
      {items.length === 0 ? (
        <p className={style.data}>No details available</p>
      ) : (
        <>
          {items.map((item, index) => {
            return (
              <Typography className={style.data} key={index}>
                {item ?? ''}
              </Typography>
            );
          })}
        </>
      )}
    </Box>
  );
};

const SalesforceAccountInformation = ({
  accountName,
  accountId,
  accountManager,
}) => {
  return (
    <>
      <DisplayData header="Salesforce account name" data={accountName} />
      <DisplayData header="Salesforce account ID" data={accountId} />
      <DisplayData header="Account manager" data={accountManager} />
    </>
  );
};

const EntitlementInformation = ({
  entitlementPacketId,
  lastUpdated,
  validFrom,
  validTo,
}) => {
  const from = validFrom ? FormatISO(validFrom, DATE_ONLY) : undefined;
  const to = validTo ? FormatISO(validTo, DATE_ONLY) : undefined;
  const updated = lastUpdated
    ? `Last updated on ${FormatISO(lastUpdated, DATE_ONLY)}`
    : undefined;

  return (
    <>
      <DisplayData
        header="Salesforce asset ID"
        data={[entitlementPacketId, updated]}
      />
      <DisplayData
        header="Entitlement Valid From and Valid To"
        data={`${from} to ${to}`}
      />
    </>
  );
};

const DisplayAssociateAccounts = ({ accounts }) => {
  // Pull out only the account names for display
  let data = accounts?.map(item => {
    return item.accountName;
  });

  return <DisplayData header="Recipient account names and IDs" data={data} />;
};

// Display the information about the linked salesforce account and entitlement
//   packets for this organization
const SalesforceContentPanel = props => {
  const { organizationToEdit, entitlementData, organizationEtag } = props;

  let accountName = organizationToEdit?.salesforceReferences?.[0]?.accountName;
  let accountId = organizationToEdit?.salesforceReferences?.[0]?.accountId;
  let accountManager =
    organizationToEdit?.salesforceReferences?.[0]?.accountManager;
  let assetId = entitlementData?.assetId;
  let validFrom = entitlementData?.validFrom;
  let validTo = entitlementData?.validTo;
  let recipients = entitlementData?.accounts?.recipients;
  let lastUpdated = entitlementData?.lastUpdated;

  return (
    <CardContent className={style.cardContent}>
      <Grid container>
        <Grid item xs={4}>
          <SalesforceAccountInformation
            accountName={accountName}
            accountId={accountId}
            accountManager={accountManager}
          />
        </Grid>
        <Grid item xs={4}>
          <EntitlementInformation
            entitlementPacketId={assetId}
            lastUpdated={lastUpdated}
            validFrom={validFrom}
            validTo={validTo}
          />
        </Grid>
        <Grid item xs={4}>
          <DisplayAssociateAccounts accounts={recipients} />
        </Grid>
        {accountName !== HEALTHWISE_ORG && (
          <Grid item xs={4}>
            <DeleteOrganization
              organization={organizationToEdit}
              organizationEtag={organizationEtag}
            />
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};

const SalesforceDetailPanel = props => {
  return (
    <section className={style.salesforceContainer}>
      <div className={style.headerContainer}>
        <h2 className={style.salesforceHeader}>
          Salesforce Account Information
        </h2>
        <ToolTip
          buttonSize="medium"
          title="This section contains information about the Primary Salesforce account (licensee) and any related (recipient) accounts."
        />
      </div>
      <Divider />
      <SalesforceContentPanel {...props} />
    </section>
  );
};

export default SalesforceDetailPanel;
