import * as configuration from 'utils/configuration/users';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const usersUrl = baseUrl + configuration.USERS_ROOT;

const resetAction = configuration.USERS_RESET_ENDPOINT;

export async function getUsers(
  skip = 0,
  top = 10,
  accessToken = null,
  search = '',
  orderByField = 'firstName',
  orderByDirection = 'asc',
) {
  return await utils.getPaginatedResult(
    usersUrl,
    skip,
    top,
    accessToken,
    search,
    orderByField,
    orderByDirection,
  );
}

export async function resetPassword(userId, accessToken) {
  var body = {
    sendEmail: true,
    userId: userId,
  };

  // Note: (TODO) Should we change the name of createResource... or change the http method for reseting a password?
  return await utils.createResource(
    `${usersUrl}/${userId}${resetAction}`,
    body,
    accessToken,
  );
}

export async function expirePassword(userId, accessToken) {
  var body = {
    tempPassword: true,
    userId: userId,
  };

  return await utils.postWithContent(
    `${usersUrl}/${configuration.USERS_EXPIRE_PASSWORD}`,
    body,
    accessToken,
  );
}

export async function modifyUser(userId, accessToken, payload, etag = '') {
  return await utils.putResource(
    `${usersUrl}/${payload.userId}`,
    payload,
    accessToken,
    etag,
  );
}

export async function modifyUserRoles(userId, accessToken, payload, etag = '') {
  return await utils.putResource(
    `${usersUrl}/${payload.userId}/roles`,
    payload.roles,
    accessToken,
    etag,
  );
}

/**
 * Lookup and retrieve a particular user by their id
 * @param {string} userId The user id
 * @param {string} accessToken A valid access token
 */
export async function getUser(userId, accessToken) {
  return await utils.getResource(`${usersUrl}/${userId}`, accessToken);
}

export async function reactivateUser(userId, accessToken) {
  return await utils.postWithoutContent(
    `${usersUrl}/${userId}/${configuration.USERS_REACTIVATE}`,
    {},
    accessToken,
  );
}

export async function activateUser(userId, accessToken) {
  return await utils.postWithoutContent(
    `${usersUrl}/${userId}/${configuration.USERS_ACTIVATE}`,
    {},
    accessToken,
  );
}

export async function deactivateUser(userId, accessToken) {
  return await utils.postWithoutContent(
    `${usersUrl}/${userId}/${configuration.USERS_DEACTIVATE}`,
    {},
    accessToken,
  );
}

export async function deleteUser(userId, accessToken) {
  return await utils.deleteResource(`${usersUrl}/${userId}`, {}, accessToken);
}
