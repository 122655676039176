import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  DialogContent,
  DialogSectionTitle,
  DialogSubSectionText,
} from 'components/dialog/dialog';
import { DomainAddIcon, SearchIcon } from 'components/icons/icons';
import Notification from 'components/notificationV2/notification';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';
import {
  BaselineTextInput,
  BaselineTextField,
} from 'components/textInputV2/textInput';

import {
  GreenRadio,
  GeneralIconButton,
  GeneralCancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import { Typography } from 'components/typography/typography';
import style from './createOrganizationModal.module.scss';

//TODO: Change color of close icon and change color of search icon on focus.
//TODO: Look into slowness of search query, data takes long to return and UI render is slow
const SearchInput = props => {
  const {
    onChange,
    onBlur,
    name,
    optionsList,
    touched,
    errorMsg,
    helpMsg,
    handleSearchQuery,
    values,
  } = props;

  const handleChange = (event, value) => {
    onChange(name, value ?? {});
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  /**
   * Card for displaying in the autocomplete when searching for clients.
   */
  const RenderSalesforceSelectCard = props => {
    const { name, sfid, manager } = props;
    return (
      <div>
        <Typography className={style.header}>{name}</Typography>
        <Typography className={style.data}>
          Account Manager: {manager}
        </Typography>
        <Typography className={style.data}>Salesforce ID: {sfid}</Typography>
      </div>
    );
  };

  return (
    <SingleSelectInput
      name={name}
      selectedValue={values[name]}
      variant="outlined"
      optionsList={optionsList}
      disableCloseOnSelect={false}
      disableClearable={true}
      handleChange={handleChange}
      handleBlur={handleBlur}
      freeSolo
      helpMsg={helpMsg}
      renderOptionCard={option => (
        <RenderSalesforceSelectCard
          name={option.name}
          sfid={option.id}
          manager={option.accountManager}
        />
      )}
      renderInputCard={params => (
        <BaselineTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment className={style.inputAdornment} position="end">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchQuery}
          helperText={touched && !!errorMsg ? errorMsg : helpMsg}
          error={!!errorMsg && touched}
          onBlur={handleBlur}
          className={style.textField}
        />
      )}
    />
  );
};

const CreateOrganizationModal = props => {
  const [open, setOpen] = useState(false);

  const {
    modalButtonText,
    isValid,
    dirty,
    isSubmitting,
    closeModal,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    searchResult,
    setSearchQuery,
    handleReset,
    status,
    searchStatus = {},
    values,
    categories,
  } = props;
  const emDash = `${'\u2014'}`;
  const { success, message = '', messageKey } = status ?? {};
  const { searchSuccess, notificationKey, errorMessage } = searchStatus;
  const [categoryValue, setCategoryValue] = useState(null);

  const orgInput = useRef(null);

  //TODO setTimeout to change focus until a better solution is found
  useEffect(() => {
    if (Object.keys(values.primarySalesforceAccount).length !== 0) {
      setTimeout(() => {
        orgInput.current?.focus();
      }, 1);
    }
  }, [values.primarySalesforceAccount]);

  const onAccountChange = (field, value, shouldValidate) => {
    if (Object.keys(value).length !== 0) {
      setFieldValue(field, value, true);
      setFieldValue('organizationName', value?.name, true);
      setFieldTouched('organizationName', true, false);
    } else {
      handleReset();
    }
  };

  const onCategoryChange = e => {
    const value = parseInt(e.target.value);
    setFieldValue('organizationCategoryId', value, true);
    setCategoryValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryValue(null);
    if (handleReset) handleReset();
  };

  // Filtering for the select box
  const handleInputChange = event => {
    debounceSearchQuery(event.target.value);
  };
  // 'debounceSearchQuery' is the function setup.
  const debounceSearchQuery = debounce(value => handleSearchQuery(value), 500);
  const handleSearchQuery = value => {
    if (value) {
      setSearchQuery(value);
    }
  };

  useEffect(() => {
    if (closeModal.current.close) {
      closeModal.current = { close: false };
      setOpen(false);
      if (handleReset) handleReset();
    }
  }, [closeModal, handleReset]);

  return (
    <>
      {success === false ? (
        <Notification error message={message} messageKey={messageKey} />
      ) : null}
      {searchSuccess === false ? (
        <Notification
          error
          message={errorMessage}
          messageKey={notificationKey}
        />
      ) : null}
      <GeneralIconButton
        className={style.modalButton}
        buttonText={modalButtonText}
        icon={<DomainAddIcon />}
        handleClick={handleOpen}
      />
      <Dialog
        aria-labelledby="CreateNewOrganizationModalTitle"
        aria-describedby="CreateNewOrganizationModalDescription"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle>Create New Organization</DialogTitle>
          <DialogContent>
            <DialogSectionTitle>1. Link Salesforce Account</DialogSectionTitle>
            <DialogSubSectionText>
              Search for an existing Licensee Salesforce account name or ID.
            </DialogSubSectionText>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12} className={style.gridItemSalesForce}>
                <SearchInput
                  name="primarySalesforceAccount"
                  optionsList={searchResult}
                  onChange={onAccountChange}
                  onBlur={setFieldTouched}
                  handleSearchQuery={handleInputChange}
                  touched={touched['primarySalesforceAccount']}
                  errorMsg={errors['primarySalesforceAccount']?.id}
                  helpMsg=""
                  values={values}
                />
              </Grid>
            </Grid>
            <Divider />
            <DialogSectionTitle>
              2. Create an Organization Name
            </DialogSectionTitle>
            <DialogSubSectionText>
              Include the client name in the organization followed by any
              descriptors that may be pertinent to this organization. (ie:
              Healthwise - Behavioral Health)
            </DialogSubSectionText>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} className={style.gridItem}>
                <BaselineTextInput
                  id="organizationName"
                  name="organizationName"
                  margin="none"
                  size="medium"
                  inputRef={orgInput}
                  disabled={
                    Object.keys(values.primarySalesforceAccount).length === 0
                  }
                />
              </Grid>
            </Grid>
            <Divider />
            <DialogSectionTitle>3. Select Organization Type</DialogSectionTitle>
            <DialogSubSectionText>
              Organization type determines which organizations will be visible
              to clients. This selection cannot be changed at a later date.
            </DialogSubSectionText>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} className={style.gridItem}>
                <RadioGroup
                  value={categoryValue}
                  onChange={onCategoryChange}
                  className={style.radioGroup}
                >
                  {categories.map(option => (
                    <FormControlLabel
                      key={option.name}
                      value={option.organizationCategoryId}
                      label={
                        option.name + ' ' + emDash + ' ' + option.description
                      }
                      control={<GreenRadio />}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton
              handleClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Create New Organization"
            ></GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

CreateOrganizationModal.propTypes = {
  modalButtonText: PropTypes.string.isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  searchResult: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired }),
  ).isRequired,
  setSearchQuery: PropTypes.func.isRequired,
};

export default CreateOrganizationModal;
