import { OktaAuth } from '@okta/okta-auth-js';

const isPkceSupported = OktaAuth.features.isPKCESupported();

export const OktaConfig = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile', 'email', 'frontdoor'],
  pkce: isPkceSupported,
  responseType: isPkceSupported ? ['code'] : ['id_token', 'token'],
  autoRenew: false,
};

export const CallbackConfig = {
  frontDoorUri:
    process.env.REACT_APP_FRONTDOOR_ISSUER || process.env.REACT_APP_OKTA_ISSUER,
  oktaIssuerUri: process.env.REACT_APP_OKTA_ISSUER,
};