/* LINK PATHS within the UI application */
export const HOME_PATH = '/';
export const USER_MANAGEMENT_PATH = '/users';
export const RELOAD_LOGIN_PATH = '/login/reload';
export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const REDIRECT_PATH = '/implicit/callback';
export const EDIT_USER_PATH = '/modify-user';
export const USER_PROFILE_PATH =
  process.env.REACT_APP_FRONTDOOR_ISSUER + '/profile';
export const CREATE_USER_PATH = '/create-user';
export const EDIT_ORGANIZATION_PATH = '/edit-organization';
export const ORGANIZATION_LIST = '/organizations';
export const ORGANIZATION_OVERVIEW_PATH = '/organizations/overview';
export const DELEGATED_ACCESS_PATH = '/delegates';
export const APPLICATIONS_PATH = '/applications';
export const EDIT_APPLICATION_PATH = '/edit-application';
export const ROLES_PATH = '/roles';

/* RESOURCE PATHS for calling the user management service */
export const GET_USER_BY_HEALTHWISE_ID = 'v1/users/';
export const CREATE_USER = 'v1/users';
export const EDIT_USER = 'v1/users';
export const GET_USERS = 'v1/users';
export const GET_ORGANIZATION = 'v1/organizations';
export const RESET_PASSWORD = userId =>
  `${GET_USER_BY_HEALTHWISE_ID}${userId}/resetPassword`;
